import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import { Link, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';

import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { useUserApi } from '../../../resources/hooks/api/userApiHook';
import { MONTHS, URLS } from '../../../resources/constants'
import { getBranch, getFirstLoginStatus } from '../../../store/user/selectors'
import '../GlClassification.css'

interface Props {

}

const SalesGlClassificationStatus: React.FC<Props> = () => {

    const { classificationListStatus, completeStep } = glClassificationApi();
    const { userStepStatus: userStepStatusFn } = useUserApi();
    const navigate = useNavigate();

    const branchData = useSelector(getBranch)
    const userStepStatus = useSelector(getFirstLoginStatus);

    const [records, setRecords] = useState<any | null>(null);
    const [posType, setPosType] = useState<string>("");
    const [months, setMonths] = useState<any | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ userStepCompleted, setUserStepCompleted ] = useState<number>(userStepStatus);
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0);

    useEffect(() => {
        const months = MONTHS.reduce((a: any, c: any) => {
            a = {
                ...a,
                [+c['value']]: c['label']
            }
            return a;
        }, {})
        setMonths(months);

        fetchList();
        fetchUserSteps();
    }, [])
    useEffect(() => {
        setUserStepCompleted(userStepStatus)
    }, [userStepStatus]);

    useEffect(() => {
        if ( userStepCompleted && userStepCompleted == 3 ) {
            userCompleteStep(4);
            navigate('/')
        }
    }, [userStepCompleted]);

    const fetchUserSteps = () => {
        setIsLoading(true);
        userStepStatusFn({ branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);

        })
    }

    const fetchList = () => {
        setIsLoading(true);
        classificationListStatus({ account_type: 2, branch_id: +branchData['id'] }, (message: string, resp: any) => {
            setIsLoading(false);
            setPosType(resp.data.data.pos_type);
            setRecords(resp.data.data.transactions);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const userCompleteStep = (step: number) => {
        setIsLoading(true);
        completeStep({ branch_id: +branchData['id'], step }, (message: string, resp: any) => {
            setIsLoading(false);
            navigate(URLS.DASHBOARD)
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 page-title align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Revenue Classification Status</h1>
                        </div>
                        {/* <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.DASHBOARD} className="btn btn-link">Back To Dashboard</Link>
                        </div> */}
                    </div>
                </div>

                {
                    userStepCompleted < 3 && records && records.length == 0 ? (
                        <Alert variant={'info'}>
                            <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;
                            {
                                posType == 'sales_with_channel' ? (
                                    <>In order to populate Sales Classification Data <br />
                                        Step 1: Close Sales quarters in RevRex <br />
                                        Step 2: Complete Revenue GL Classification</>
                                ) : (
                                    // <>In order to populate Sales Classification Data complete Revenue GL Classification</>
                                    <>In order to complete Sales Classification please close the months for Sales Activity in RevRex APP</>
                                )
                            }
                        </Alert>
                    )  : userStepCompleted >= 3 && records && records.length == 0 ? (
                        <Alert variant={'info'}>
                            <i className="fa fa-exclamation-triangle fa-lg" aria-hidden="true"></i>&nbsp;&nbsp;
                            You have nothing to classify here. Please go back to dashboard.
                        </Alert>
                    ) : <></>
                }

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                            <div className="loader"></div>
                        </div>
                        <div className="table-responsive list-table table-scroll">
                            <table className="table table-default">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="text-center">Month</th>
                                        <th className="text-center">Year</th>
                                        <th className="text-center">Status </th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records && records.map((data: any, index: number) => (
                                            <tr className="align-middle h-60" key={index}>
                                                <td className="text-center">
                                                    {months[data['item']['month']]}
                                                </td>
                                                <td className="text-center">
                                                    {data['item']['year']}
                                                </td>
                                                <td className="text-center">
                                                    <h6>
                                                        {data['status'] == 'posted' ? <Badge bg="success">Completed</Badge> : data['status'] == 'partial_posted' ? <Badge bg="warning">Pending</Badge> : <Badge bg="warning">Pending</Badge>}
                                                    </h6>
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        data['status'] != 'posted' && <Link to={`/revenue-gl-classification/${data['item']['month']}/${data['item']['year']}`}>Click Here To Classify</Link> || <span>NA</span>
                                                    }
                                                </td>
                                            </tr>))
                                    }
                                    {
                                        records && records.length == 0 && (
                                            <tr>
                                                <td colSpan={4} className='text-center'>
                                                    <p>You have no records to classify.</p>
                                                    {
                                                        userStepCompleted < 3 && (
                                                            <button className='btn btn-cancel' onClick={() => userCompleteStep(3)}>Click here to skip this step</button>
                                                        ) || <></>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={npage} /> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SalesGlClassificationStatus;