import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { useForm, Controller, useFormContext, useFieldArray } from "react-hook-form";
import filterSolidIcon from '../../../assets/images/filter-solid-icon.svg';
import toast from 'react-hot-toast';
import { SalesSearchFormI, SalesGlClassificationSingleFormI } from '../../../resources/form-props';
import { currencyFormat } from '../../../resources/helper'
import "react-datepicker/dist/react-datepicker.css";
import { SalesGlClassificationFormI } from '../../../resources/form-props';
import SAlert from '../../../components/SAlert';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import useDebounce from '../../../resources/hooks/UseDebounce';
import { Box, FormControlLabel, Checkbox } from '@mui/material'
import { glClassificationApi } from '../../../resources/hooks/api/glClassificationApi';
import { GLSearchFormValidate, SalesExpenseValidate } from '../../../resources/form-validator';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'

import '../GlClassification.css'
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import { calculateMaxHeightContent, lastYears } from '../../../resources/functions';
import SelectCustomOption from '../../../components/SelectCustomOption';

import { SelectInstance } from 'react-select'; // Import the correct type
import SplitModal from 'components/Modal/SalesClassification/SplitModal';
import { Button } from 'react-bootstrap';

interface Props {

}

const SalesGlClassification: React.FC<Props> = () => {

    const { glAccountsList } = useChartAccountApi();
    const { glSaleslist, saleGlUpdate, revenueGlUpdate } = glClassificationApi();
    const urlParams = useParams();
    const navigate = useNavigate();
    const branchData = useSelector(getBranch)

    const [saleRecords, setSaleRecords] = useState<any>({
        type: null,
        data: []
    });
    const [glAllAccount, setGlAllAccount] = useState<any[]>([]);
    const [glSalesAccount, setGlSalesAccount] = useState<any[]>([]);
    const [glExcludeSaleAccount, setGlExcludeSaleAccount] = useState<any[]>([]);
    const [checked, setChecked] = useState<any>([]);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const checkAllRef = useRef<HTMLInputElement>(null);
    const [overShortLabel, setOverShortLabel] = useState<string>('GL Account');
    const [contentMaxHeight, setContentMaxHeight] = useState<number>(0)
    const [selectedCalendar, setSelectedCalendar] = useState<any>({
        year: 2024,
        month: 1
    });
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));

    const { register, trigger, control, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm<SalesGlClassificationFormI>({
        defaultValues: {
            sales: [],
        },
        resolver: yupResolver(SalesExpenseValidate)
    });
    const { fields, append: formAppend, remove: formRemove } = useFieldArray({
        control,
        name: "sales"
    });
    const [showSplitModal, setShowSplitModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<any>(null);

    const { control: control2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm<SalesSearchFormI>({
        defaultValues: {
            month: {
                value: '01',
                label: 'January'
            },
            year: { value: '2024', label: '2024' },
        },
        resolver: yupResolver(GLSearchFormValidate)
    });


    useEffect(() => {
        fetchTransactionList();
        fechAccountList('', [], []).then(r => {
            setGlAllAccount(r);
        })
        fechAccountList('', [], GL_VP.SALES_ACTIVITY.account).then(r => {
            setGlSalesAccount(r);
        })
        fechAccountList('', GL_VP.SALES_ACTIVITY.account, []).then(r => {
            setGlExcludeSaleAccount(r);
        })
    }, [])

    useEffect(() => {
        saleRecords['data'].map((record: any, index: number) => {
            const formData: any = {};

            if (saleRecords['type'] == 'sales_with_channel') {
                const grossSaleData = record['breakdowns'].filter((e: any) => e['account_type'] == "Gross_Sale");
                const stateTaxData = record['breakdowns'].filter((e: any) => e['account_type'] == "State_Tax");
                const overseeData = record['breakdowns'].filter((e: any) => e['account_type'] == "Overshort");

                setOverShortLabel(overseeData.length && overseeData[0]['txn_type'] == 1 ? 'Debit GL Account' : 'Credit GL Account')

                formData['formIndex'] = index;
                formData['checked'] = false;
                formData['record_id'] = record['id'];
                formData['parent_id'] = record['parent_id'];
                formData['description'] = record['description'];
                formData['account_name'] = record['account_name'];
                formData['transaction_at'] = record['transaction_at'];
                formData['gross_sale'] = grossSaleData.length && grossSaleData[0]['account_value'] || 0;
                formData['state_tax'] = stateTaxData.length && stateTaxData[0]['account_value'] || 0;
                formData['gross_sale_master_account_id'] = grossSaleData.length && grossSaleData[0]['account_chart'] && {
                    label: grossSaleData[0]['account_chart']['name'] + `(${grossSaleData[0]['account_chart']['account']})`,
                    value: grossSaleData[0]['account_chart']['id']
                } || null;
                formData['state_tax_master_account_id'] = null;
                formData['overshort'] = overseeData.length && (overseeData[0]['txn_type'] == 1 && overseeData[0]['account_value'] || overseeData[0]['account_value'] * -1) || 0;
                formData['overshort_master_account_id'] = null;
                formData['split'] = [];
            } else if (saleRecords['type'] == 'sales_without_channel') {

                formData['formIndex'] = index;
                formData['checked'] = false;
                formData['record_id'] = +record['transaction_account_id'];
                formData['parent_id'] = record['transaction'] && record['transaction']['parent_id'] || null;
                formData['description'] = record['transaction'] && record['transaction']['description'] || '';
                formData['account_name'] = record['transaction'] && record['transaction']['account_name'] || '';
                formData['transaction_at'] = record['transaction'] && record['transaction']['transaction_at'] || '';
                formData['gross_sale'] = record['account_value'] || 0;
                formData['gross_sale_master_account_id'] = null;
                formData['split'] = [];
            }

            formAppend(formData)
        })
    }, [saleRecords['data']])

    // useEffect(() => {
    //     fetchTransactionList();
    // }, [selectedCalendar])

    useEffect(() => {
        setContentMaxHeight(calculateMaxHeightContent(131));
    })

    useEffect(() => {
        if (fields.length == 0) {
            if (checkAllRef.current) {
                checkAllRef.current.checked = false;
            }
        }
    }, [fields])

    const fetchTransactionList = () => {
        const params: any = {
            "page": 1,
            "per_page": 500,
            year: urlParams['year'],
            month: urlParams['month'],
            branch_id: +branchData['id'],
            is_updated: 0
        }

        setIsLoading(true);
        glSaleslist(params, (message: string, resp: any) => {
            reset();
            setSaleRecords({
                type: resp.data.data.type,
                data: resp.data.data.items.result
            });
            setIsLoading(false);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }
    const fechAccountList = (searchText: string = '', excludeVP: number[] = [], includeVP: number[] = []): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            if (excludeVP.length) {
                params['parent_account_not_in'] = excludeVP.join(',')
            }
            if (includeVP.length) {
                params['parent_account_in'] = includeVP.join(',')
            }

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const handleCheckAllChange = (e: any) => {
        if (checkAllRef.current) {
            checkAllRef.current.checked = e.target.checked;

            fields.map((record: any, index: number) => {
                setValue(`sales.${index}.checked`, e.target.checked)
            })
            /*saleRecords['data'].map((record: any, index: number) => {
                setValue(`sales.${index}.checked`, e.target.checked)
            })*/
        }

        // setChecked(
        //     e.target.checked ? records.map((c: any) => c.id) : []
        // );
    };

    const handleCheckAll = (e: any, index: number) => {
        setValue(`sales.${index}.checked`, e.target.checked);
        if (e.target.checked) {
            const checkedRecords = fields.filter((record: any, index: number) => getValues(`sales.${index}.checked`) === true);

            if (checkedRecords.length == fields.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = true;
                }
            }
        } else {
            const uncheckedRecords = fields.filter((record: any, index: number) => getValues(`sales.${index}.checked`) === false);

            if (uncheckedRecords.length) {
                if (checkAllRef.current) {
                    checkAllRef.current.checked = false;
                }
            }
        }
    }

    const filterAllAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, [], [])
        return data;
    };
    const filterSalesAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, [], GL_VP.SALES_ACTIVITY.account)
        return data;
    };
    const filterExceptSalesAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue, GL_VP.SALES_ACTIVITY.account, [])
        return data;
    };

    const handleCheckboxChange = (e: any, c: any) => {
        // alert();
        setChecked((prevChecked: any) =>
            e.target.checked
                ? [...prevChecked, c.id]
                : prevChecked.filter((item: any) => item !== c.id)
        );
    };

    const [selectedRowID, setSelectedRowID] = useState<number | null>(null);
    const [indexNo, setIndexNo] = useState<number | null>(null);
    const handleSplitClick = (data: any, id: number) => {


        // Optionally find the specific item in the array and add the index if needed
        // For simplicity, we'll just push the index to the end of the array

        setSelectedRecord(data);
        setShowSplitModal(true);
    };
    const [splitData, setSplitData] = React.useState<any[]>([]);
    const handleSplitSave = (rowID: number, indexNo: number, splits: any[]) => {
        // Create new splits with the rowID added
        const newSplits = splits.map(split => ({ ...split }));

        // Update splitData state
        setSplitData(prevData => {
            // Filter out existing splits with the same rowID
            const updatedSplits = prevData.filter(split => split.rowID !== rowID);
            // Merge new splits with the updated data
            return [...updatedSplits, ...newSplits];
        });


        // Remove rowID from newSplits
        const finalSplits = newSplits.map(split => {
            const { rowID, ...rest } = split;
            return rest;
        });

        //console.log(finalSplits);

        // Ensure setValue is called with the latest splitData
        setValue(`sales.${indexNo}.split`, finalSplits);


    };

    const processData = (data: any) => {
        // console.log(data);
        const toRemoveItems: number[] = [];
        const records = data['sales']
            // .filter((e: any) => e['checked'])
            .map((d: any, index: number) => {
                if (d['checked']) {
                    toRemoveItems.push(index)
                    var data: any = {};
                    let isFirstRow = true; // Track if it's the first row for setting id

                    // Process the existing split data within the record
                    let split = d['split']
                        .map((split: any) => {
                            if (isFirstRow) {
                                isFirstRow = false;
                                return {
                                    ...split,
                                    id: split.id, // Only first item retains id
                                    gross_sale_master_account_id: split.gross_sale_master_account_id
                                        ? split.gross_sale_master_account_id.value
                                        : null,
                                };
                            } else {
                                return {
                                    ...split,
                                    id: null, // Set id to null for subsequent entries
                                    gross_sale_master_account_id: split.gross_sale_master_account_id
                                        ? split.gross_sale_master_account_id.value
                                        : null,
                                };
                            }
                        })
                        .filter((item: any) => item.gross_sale_master_account_id !== null); // Filter out splits with null gross_sale_master_account_id

                    // If no split data found, add a default split entry
                    if (split.length === 0) {
                        split = [
                            {
                                id: d.record_id,
                                gross_sale: d.gross_sale,
                                gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            },
                        ];
                    }


                    if (saleRecords['type'] == 'sales_with_channel') {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            // gross_sale: d.gross_sale,
                            state_tax: d.state_tax,
                            overshort: Math.abs(d.overshort), // as need to submit the abs value to backend
                            // gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            state_tax_master_account_id: d['state_tax_master_account_id'] && d['state_tax_master_account_id']['value'] && +d['state_tax_master_account_id']['value'] || null,
                            overshort_master_account_id: d['overshort_master_account_id'] && d['overshort_master_account_id']['value'] && +d['overshort_master_account_id']['value'] || null,
                            split: split // Add filtered split_data array here
                        }
                    } else {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            // gross_sale: d.gross_sale,
                            // gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            split: split // Add filtered split_data array here
                        }
                    }
                    return data;
                } else {
                    return null
                }
            }).filter((e: any) => e);
        // const filtered = records.filter((item: any) => item !== null)

        // console.log(records);

        postData(records, toRemoveItems);
        /*if ( records.length ) {
            setIsLoading(true);
            
            if ( saleRecords['type'] == 'sales_with_channel' ) {
                saleGlUpdate(records, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.success(message)
                    formRemove(toRemoveItems)
                    fetchTransactionList();
                }, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.error(message);
                })
            } else {
                revenueGlUpdate(records, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.success(message)
                    formRemove(toRemoveItems)
                    fetchTransactionList();
                }, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.error(message);
                })
            }
            
        } else {
            toast.error("Please select atleast one record");
        }*/
    }
    const postData = (records: any, toRemoveItems: number[]) => {
        if (records.length) {
            setIsLoading(true);

            //if ( saleRecords['type'] == 'sales_with_channel' ) {
            saleGlUpdate({ records, branch_id: +branchData['id'] }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.success(message)
                formRemove(toRemoveItems)
                if (records.length == fields.length) {
                    navigate(-1);
                }
                // fetchTransactionList();
            }, (message: string, resp: any) => {
                setIsLoading(false);
                toast.error(message);
            })
            /*} else {
                revenueGlUpdate({records, branch_id: +branchData['id']}, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.success(message)
                    formRemove(toRemoveItems)
                    if ( records.length == fields.length ) {
                        navigate(-1);
                    }
                    // fetchTransactionList();
                }, (message: string, resp: any) => {
                    setIsLoading(false);
                    toast.error(message);
                })
            }*/

        } else {
            toast.error("Please select at least one record");
        }
    }
    const handleSinglePost = (index: number) => {
        // processData(data);
        const data = getValues();
        const toRemoveItems: number[] = [];
        if (data['sales'][index]['checked']) {
            const records = [data['sales'][index]]
                .map((d: any) => {
                    var data: any = {};

                    if (saleRecords['type'] == 'sales_with_channel') {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            gross_sale: d.gross_sale,
                            state_tax: d.state_tax,
                            overshort: d.overshort,
                            gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                            state_tax_master_account_id: d['state_tax_master_account_id'] && d['state_tax_master_account_id']['value'] && +d['state_tax_master_account_id']['value'] || null,
                            overshort_master_account_id: d['overshort_master_account_id'] && d['overshort_master_account_id']['value'] && +d['overshort_master_account_id']['value'] || null,
                        }
                    } else {
                        data = {
                            id: d.record_id,
                            parent_id: d.parent_id,
                            description: d.description,
                            account_name: d.account_name,
                            transaction_at: d.transaction_at,
                            gross_sale: d.gross_sale,
                            gross_sale_master_account_id: d['gross_sale_master_account_id'] && d['gross_sale_master_account_id']['value'] && +d['gross_sale_master_account_id']['value'] || null,
                        }
                    }
                    return data;
                });
            toRemoveItems.push(index)

            postData(records, toRemoveItems)
        } else {
            toast.error("Please select the record");
        }
    }
    const processSearchData = (data: any) => {
        setSelectedCalendar({
            year: +data['year']['value'],
            month: +data['month']['value']
        })
    }
    const handleFieldValidation = async (index: number) => {
        // Trigger validation for the specified field within the array
        const isValidated = await trigger(`sales.${index}`);
        if (isValidated) {
            handleSinglePost(index)
        }
    };

    const selectRefs = useRef<SelectInstance<any, false, any>[]>([]);


    const handleSelectChange = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs.current.length) {
                const nextSelect = selectRefs.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };
    const selectRefs2 = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange2 = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs2.current.length) {
                const nextSelect = selectRefs2.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };
    const selectRefs3 = useRef<SelectInstance<any, false, any>[]>([]);
    const handleSelectChange3 = (index: number, e: any) => {
        setTimeout(() => {
            let nextIndex = index + 1;
            while (nextIndex < selectRefs3.current.length) {
                const nextSelect = selectRefs3.current[nextIndex];
                if (nextSelect && !nextSelect.props.isDisabled) {
                    nextSelect.focus();
                    break;
                }
                nextIndex++;
            }
        }, 0);
    };


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="page-title pb-4 pt-3" id="ux_page_title">
                    <div className="row align-items-center">
                        <div className="col-sm-6 align-items-center d-flex">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Revenue Classification</h1>
                        </div>
                        <div className="col-sm-6 justify-content-end align-items-center d-flex">
                            <Link to={URLS.SALES_GL_CLASSIFICATION_STATUS} className="btn btn-link">Back To Classification Status</Link>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className="filter-btn">
                            <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                                <div className="loader"></div>
                            </div>
                        </div>

                        <Form >
                            {
                                fields.length > 0 && (
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleSubmit(processData)}>Post</button>
                                    </div> || <></>
                                )
                            }
                            <br></br>
                            <div className="table-responsive list-table table-scroll" style={{ maxHeight: contentMaxHeight }}>
                                <table className="table table-default">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="w-5 text-center">
                                                <div className="form-check ">
                                                    <label >
                                                        <input ref={checkAllRef} className="form-check-input wh-20 checkAll" type="checkbox" onChange={handleCheckAllChange} />
                                                    </label>
                                                </div>
                                            </th>

                                            <th className="text-center">Date</th>
                                            <th className="text-start">Description </th>
                                            <th className="text-end">Net Sales </th>
                                            <th className="text-start">{saleRecords.type == 'sales_with_channel' ? 'Credit' : ''} GL Account </th>
                                            <th></th>
                                            {
                                                saleRecords.type == 'sales_with_channel' && (
                                                    <>
                                                        <th className="text-end">State Tax </th>
                                                        <th className="text-start">Debit GL Account </th>
                                                        <th className="text-end">Over / Short</th>
                                                        <th className="text-start">{overShortLabel}</th>
                                                    </>
                                                ) || <></>
                                            }

                                            {/* <th className="text-center">Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fields.length > 0 ?
                                                fields.map((data: any, index: any) => {

                                                    // Find split data for the current row
                                                    const hasSplitData = splitData.some((split: any) => split.id === data.record_id);

                                                    // Extract split debit account IDs
                                                    const splitCreditedAccounts = splitData
                                                        .filter((split) => split.id === data.record_id)
                                                        .map((split) => split.gross_sale_master_account_id.label)
                                                        .join(', ');

                                                    return (
                                                        <tr className="align-middle h-60" key={data['id']}>
                                                            <td className="text-center">
                                                                <div className="form-check ">
                                                                    <label>
                                                                        <input className="form-check-input wh-20 checkbox" {...register(`sales.${index}.checked`)}
                                                                            name={`sales.${index}.checked`}
                                                                            defaultChecked={fields[index]['checked']}
                                                                            type="checkbox" onChange={(e: any) => handleCheckAll(e, index)} />
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td className="text-center"><strong>{moment(fields[index].transaction_at).format('MM-DD-YYYY h:mm:ss')}</strong></td>
                                                            <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={fields[index].description || 'NA'}>{fields[index].description || 'NA'}</td>

                                                            <td className="text-end">{currencyFormat(fields[index].gross_sale)}</td>
                                                            {
                                                                saleRecords.type == 'sales_with_channel' && (
                                                                    <>

                                                                        {
                                                                            hasSplitData ? (

                                                                                <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitCreditedAccounts || 'NA'}>
                                                                                    {splitCreditedAccounts}
                                                                                </td>
                                                                            ) : (
                                                                                <td className="text-start">
                                                                                    <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                        <Controller
                                                                                            control={control}
                                                                                            //disabled={fields[index].gross_sale == 0 || !!fields[index].gross_sale_master_account_id}
                                                                                            name={`sales.${index}.gross_sale_master_account_id`}
                                                                                            render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                                <AsyncSelect
                                                                                                    isClearable={true}
                                                                                                    placeholder={'Please select GL acc'}
                                                                                                    defaultOptions={glSalesAccount}
                                                                                                    loadOptions={filterSalesAccountList}
                                                                                                    // onChange={(e:any) => {
                                                                                                    //     onChange(e);
                                                                                                    //     // Manually blur the input field to remove focus
                                                                                                    //     const activeElement = document.activeElement as HTMLElement;
                                                                                                    //     activeElement?.blur();
                                                                                                    //   }}
                                                                                                    ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                                    onChange={(e: any) => {
                                                                                                        onChange(e);
                                                                                                        handleSelectChange(index, e);
                                                                                                    }}
                                                                                                    className='vp-dropdown'
                                                                                                    menuPosition='fixed'
                                                                                                    value={value}
                                                                                                    defaultValue={value}
                                                                                                    isDisabled={fields[index].gross_sale == 0 || !!fields[index].gross_sale_master_account_id}
                                                                                                    styles={selectCustomStyle}
                                                                                                    components={{ Option: SelectCustomOption }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    {
                                                                                        errors && errors.sales && errors.sales[index] && errors.sales[index]?.gross_sale_master_account_id && errors.sales[index]?.gross_sale_master_account_id?.message && (
                                                                                            <Form.Text className="d-flex text-danger">
                                                                                                <strong>
                                                                                                    {errors.sales[index]?.gross_sale_master_account_id?.message as ReactNode || ''}
                                                                                                </strong>
                                                                                            </Form.Text>
                                                                                        ) || ''
                                                                                    }

                                                                                </td>

                                                                            )
                                                                        }
                                                                        <td>
                                                                            <Button variant="primary" disabled={fields[index].gross_sale === 0} onClick={() => handleSplitClick(data, fields[index].record_id)}>
                                                                                {hasSplitData ? 'Modify Split' : 'Split Classification'}
                                                                            </Button>
                                                                        </td>
                                                                        <td className="text-end">{currencyFormat(fields[index].state_tax)}</td>
                                                                        <td className="text-start">
                                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                <Controller
                                                                                    control={control}
                                                                                    //disabled={fields[index].overshort != 0 || fields[index].state_tax == 0 || !!fields[index].state_tax_master_account_id}
                                                                                    name={`sales.${index}.state_tax_master_account_id`}
                                                                                    render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                        <AsyncSelect
                                                                                            isClearable={true}
                                                                                            placeholder={'Please select GL acc'}
                                                                                            defaultOptions={glExcludeSaleAccount}
                                                                                            loadOptions={filterExceptSalesAccountList}
                                                                                            // onChange={(e:any) => {
                                                                                            //     onChange(e);
                                                                                            //     // Manually blur the input field to remove focus
                                                                                            //     const activeElement = document.activeElement as HTMLElement;
                                                                                            //     activeElement?.blur();
                                                                                            //   }}
                                                                                            ref={(el) => (selectRefs2.current[index] = el as SelectInstance<any, false, any>)}
                                                                                            onChange={(e: any) => {
                                                                                                onChange(e);
                                                                                                handleSelectChange2(index, e);
                                                                                            }}
                                                                                            className='vp-dropdown'
                                                                                            menuPosition='fixed'
                                                                                            isDisabled={fields[index].overshort != 0 || fields[index].state_tax == 0 || !!fields[index].state_tax_master_account_id}
                                                                                            styles={selectCustomStyle}
                                                                                            components={{ Option: SelectCustomOption }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Form.Group>
                                                                            {
                                                                                errors && errors.sales && errors.sales[index] && errors.sales[index]?.state_tax_master_account_id && errors.sales[index]?.state_tax_master_account_id?.message && (
                                                                                    <Form.Text className="text-danger d-flex">
                                                                                        <strong>
                                                                                            {errors.sales[index]?.state_tax_master_account_id?.message as ReactNode || ''}
                                                                                        </strong>
                                                                                    </Form.Text>
                                                                                ) || ''
                                                                            }
                                                                        </td>


                                                                        <td className="text-end">{currencyFormat(fields[index].overshort)}</td>
                                                                        <td className="text-start">
                                                                            <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                <Controller
                                                                                    control={control}
                                                                                    //disabled={fields[index].overshort == 0 || !!fields[index].overshort_master_account_id}
                                                                                    name={`sales.${index}.overshort_master_account_id`}
                                                                                    render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                        <AsyncSelect
                                                                                            isClearable={true}
                                                                                            placeholder={'Please select GL acc'}
                                                                                            defaultOptions={glExcludeSaleAccount}
                                                                                            loadOptions={filterExceptSalesAccountList}
                                                                                            // onChange={(e:any) => {
                                                                                            //     onChange(e);
                                                                                            //     // Manually blur the input field to remove focus
                                                                                            //     const activeElement = document.activeElement as HTMLElement;
                                                                                            //     activeElement?.blur();
                                                                                            //   }}
                                                                                            ref={(el) => (selectRefs3.current[index] = el as SelectInstance<any, false, any>)}
                                                                                            onChange={(e: any) => {
                                                                                                onChange(e);
                                                                                                handleSelectChange3(index, e);
                                                                                            }}
                                                                                            className='vp-dropdown'
                                                                                            menuPosition='fixed'
                                                                                            isDisabled={fields[index].overshort == 0 || !!fields[index].overshort_master_account_id}
                                                                                            styles={selectCustomStyle}
                                                                                            components={{ Option: SelectCustomOption }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Form.Group>
                                                                            {
                                                                                errors && errors.sales && errors.sales[index] && errors.sales[index]?.overshort_master_account_id && errors.sales[index]?.overshort_master_account_id?.message && (
                                                                                    <Form.Text className="text-danger d-flex">
                                                                                        <strong>
                                                                                            {errors.sales[index]?.overshort_master_account_id?.message as ReactNode || ''}
                                                                                        </strong>
                                                                                    </Form.Text>
                                                                                ) || ''
                                                                            }
                                                                        </td>
                                                                    </>
                                                                ) || (
                                                                    <>
                                                                        {
                                                                            hasSplitData ? (

                                                                                <td className="text-start text-truncate" style={{ maxWidth: '250px' }} title={splitCreditedAccounts || 'NA'}>
                                                                                    {splitCreditedAccounts}
                                                                                </td>
                                                                            ) : (
                                                                                <td className="text-start">
                                                                                    <Form.Group className="col-sm-12 d-flex" controlId="formBasicEmail">
                                                                                        <Controller
                                                                                            control={control}
                                                                                            //disabled={fields[index].gross_sale == 0 || !!fields[index].gross_sale_master_account_id}
                                                                                            name={`sales.${index}.gross_sale_master_account_id`}
                                                                                            render={({ field: { value, onChange, onBlur, disabled } }) => (
                                                                                                <AsyncSelect
                                                                                                    isClearable={true}
                                                                                                    placeholder={'Please select GL acc'}
                                                                                                    defaultOptions={glSalesAccount}
                                                                                                    loadOptions={filterSalesAccountList}
                                                                                                    // onChange={(e:any) => {
                                                                                                    //     onChange(e);
                                                                                                    //     // Manually blur the input field to remove focus
                                                                                                    //     const activeElement = document.activeElement as HTMLElement;
                                                                                                    //     activeElement?.blur();
                                                                                                    //   }}
                                                                                                    ref={(el) => (selectRefs.current[index] = el as SelectInstance<any, false, any>)}
                                                                                                    onChange={(e: any) => {
                                                                                                        onChange(e);
                                                                                                        handleSelectChange(index, e);
                                                                                                    }}
                                                                                                    className='vp-dropdown'
                                                                                                    menuPosition='fixed'
                                                                                                    value={value}
                                                                                                    defaultValue={value}
                                                                                                    isDisabled={fields[index].gross_sale == 0 || !!fields[index].gross_sale_master_account_id}
                                                                                                    styles={selectCustomStyle}
                                                                                                    components={{ Option: SelectCustomOption }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    {
                                                                                        errors && errors.sales && errors.sales[index] && errors.sales[index]?.gross_sale_master_account_id && errors.sales[index]?.gross_sale_master_account_id?.message && (
                                                                                            <Form.Text className="d-flex text-danger">
                                                                                                <strong>
                                                                                                    {errors.sales[index]?.gross_sale_master_account_id?.message as ReactNode || ''}
                                                                                                </strong>
                                                                                            </Form.Text>
                                                                                        ) || ''
                                                                                    }

                                                                                </td>

                                                                            )
                                                                        }

                                                                        <td>
                                                                            <Button variant="primary" disabled={fields[index].gross_sale === 0} onClick={() => handleSplitClick(data, fields[index].record_id)}>
                                                                                {hasSplitData ? 'Modify Split' : 'Split Classification'}
                                                                            </Button>
                                                                        </td>
                                                                    </>
                                                                )
                                                            }

                                                            {/* <td className="text-center">
                                                            <button type="button" className="btn btn-secondary" onClick={() => handleFieldValidation(index)}>Post</button>
                                                        </td> */}

                                                        </tr>
                                                    );
                                                }) : <tr>
                                                    <td colSpan={saleRecords.type == 'sales_with_channel' ? 9 : 7} className='text-center'>No data found</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>

                            </div>
                        </Form>
                        {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} recordPerPage={recordPerPage} lastIndex={lastIndex} firstIndex={firstIndex} npage={npage} /> */}
                    </div>
                </div>
            </div>
            <SplitModal
                rowID={selectedRecord?.record_id || 0}
                indexNo={selectedRecord?.formIndex || 0}
                show={showSplitModal}
                onHide={() => setShowSplitModal(false)}
                onSave={handleSplitSave}
                originalAmount={selectedRecord?.gross_sale || 0}
                glSalesAccount={glSalesAccount}
                creditAccountName={selectedRecord?.deposit_credit_account_id?.label || ''}
                creditAccountId={selectedRecord?.deposit_credit_account_id?.value || ''}
                transactionAt={selectedRecord?.transaction_at || ''}
                description={selectedRecord?.description || ''}
                splitData={splitData}
                filterSalesAccountList={filterSalesAccountList}

            />
        </div>

    )
}

export default SalesGlClassification;